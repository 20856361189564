<template>
  <div>
    <div
      id="admin-property-view"
      class="property-view"
      style="  background:#F2F4F4;height:100vh;"
    >
      <div class="text-center" v-if="!development || !properties">
        <div class="lds-dual-ring"></div>
      </div>
      <v-container v-if="development && properties">
        <quote-stepper
          :propertiesGet="properties"
          :developmentGet="development"
          :priceRulesGet="priceRules"
          :discountsGet="discounts"
        />
      </v-container>

      <div v-if="error">
        <h1>No se pudo obtener los datos.</h1>
      </div>
    </div>
  </div>
</template>
<script>
//Falta traer todos los descuentos del desarrollo...

import {
  GetRealEstateDevelopmentDetail,
  GetDiscountList
} from "@/api/real-estate-development-api.js";
import { GetPropertiesAndDevelopment } from "@/api/property-api.js";

export default {
  components: {
    QuoteStepper: () => import("@/components/quoter/QuoteStepper.vue")
  },
  name: "AdvancedQuoter",
  data() {
    return {
      nameDevelopment: "",
      error: false,
      //Variables para guardar consultas
      development: null,
      properties: null,
      priceRules: null,
      discounts: null
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (this.$route.params.code) {
        this.getDevelopment(this.$route.params.code);
        this.getProperties(this.$route.params.code);
      } else {
        this.error = true;
      }
    },
    getProperties(code) {
      GetPropertiesAndDevelopment(code)
        .then(res => {
          if (res && res.data) {
            if (res.data.Property) {
              this.properties = res.data.Property.filter(
                item =>
                  item.contract_status.trim().toLowerCase() === "disponible"
              );

              if (
                res.data.Developments[0] &&
                res.data.Developments[0].pricing_rules
              ) {
                this.priceRules = res.data.Developments[0].pricing_rules;
              }
            }
          }
        })
        .catch(error => (error.this.error = true))
        .finally();
    },
    getDevelopment(code) {
      GetRealEstateDevelopmentDetail(code)
        .then(res => {
          this.development = res.RealEstateDevelopment;
          this.getDiscount(this.development._id);
        })
        .catch(error => error)
        .finally();
    },
    getDiscount(id) {
      GetDiscountList(id)
        .then(res => {
          this.discounts = res.Discounts;
        })
        .catch(error => error)
        .finally();
    }
  }
};
</script>
<style scoped>
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
  position: fixed;
  top: 50%;
  left: 50%;
  background: "green";
  color: darkred;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #262d3c;
  border-color: #262d3c transparent #262d3c transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
